import React from "react";
import {Button} from 'antd'
import PropTypes from 'prop-types'


export const CarouselArrow = ({shape, icon, style, onClick, className}) => 
   (<Button
    shape={shape}
    icon={icon}
    style={style}
    onClick={onClick}
    ghost
    className={className}
    />) 
  
CarouselArrow.propTypes = {
    shape:PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    style: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string.isRequired
}
