import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types'

const {Paragraph, Text} = Typography

export const ContactUs = ({classNameForParagraph, classNameForText}) => {
    const { t } = useTranslation('', {
        keyPrefix: 'loginPage',
      });

    return  (
        <Paragraph className={classNameForParagraph}>
            {t('havingTrobleToLogin')}{' '}
            <a href="mailto:support@chaosarchitects.com">
                <Text className={classNameForText}>
                    {t('contactUs')}
                </Text>
            </a>
        </Paragraph>
    )
}

ContactUs.propTypes = {
    classNameForParagraph: PropTypes.string.isRequired,
    classNameForText: PropTypes.string.isRequired
}