import { Button, Divider, Flex, Form, Input, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  loginGoogleUser as loginGoogleUserAction,
  loginMicrosoftUser as loginMicrosoftUserAction,
  loginUser as loginUserAction,
} from '../../../actions';
import GoogleIcon from '../../../images/icons/icon_google.svg';
import MicrosoftIcon from '../../../images/icons/icon_microsoft.svg';
import LoginImage from '../../../images/loginForm-image.png';
import { BgImage } from './BgImage';
import { BrandLogo } from './BrandLogo';
import { ContactUs } from './ContactUs';

const { Title, Paragraph } = Typography;

const LoginForm = ({
  isError,
  isLoading,
  errorMessage,
  loginGoogleUser,
  loginMicrosoftUser,
  loginUser,
}) => {
  const loginWithMicrosoft = (e) => {
    e.preventDefault();
    loginMicrosoftUser();
  };

  const loginWithGoogle = (e) => {
    e.preventDefault();
    loginGoogleUser();
  };

  const { t } = useTranslation('', {
    keyPrefix: 'loginPage',
  });

  return (
    <Flex
      horizontal="true"
      className="tw-w-full tw-h-screen tw-p-0 tw-justify-center tw-items-center tw-relative"
    >
      <Flex
        vertical
        style={{ height: '630px' }}
        className="tw-justify-center tw-items-center lg:tw-w-3/5 "
      >
        <BrandLogo className="tw-w-14 tw-h-14 tw-items-center tw-justify-center" />

        <Form
          requiredMark={false}
          layout="vertical"
          style={{ maxWidth: '480px' }}
          onFinish={loginUser}
        >
          <Title
            level={1}
            className="tw-text-center tw-font-medium tw-text-4xl leading-tight"
          >
            {t('welcomtoChaos')}
          </Title>

          <Paragraph className="tw-text-center tw-text-sm tw-font-normal tw-text-slate-400">
            {t('loginPageIntroText')}
          </Paragraph>
          <Space direction="vertical" size="small" className="tw-w-full">
            <Button
              size="large"
              className="tw-w-full"
              onClick={loginWithMicrosoft}
              icon={
                <img
                  src={MicrosoftIcon}
                  style={{ height: 24, width: 24 }}
                  alt="microsoft-icon"
                />
              }
            >
              {t('signInWithMicrosoft')}
            </Button>
            <Button
              size="large"
              className="tw-w-full"
              onClick={loginWithGoogle}
              icon={
                <img
                  src={GoogleIcon}
                  style={{ height: 22, width: 22 }}
                  alt="google-icon"
                />
              }
            >
              <span className="tw-mr-3">{t('signInWithGoogle')}</span>
            </Button>
          </Space>

          <Divider>{t('SignInWithEmail')}</Divider>

          <Space direction="vertical" size="large" className="tw-w-full">
            <Space direction="vertical" size="small" className="tw-w-full">
              <Form.Item
                label={t('email')}
                name="email"
                rules={[
                  { required: true, message: t('emailIsRequired') },
                  { type: 'email', message: t('emailIsNotValid') },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('password')}
                name="password"
                rules={[
                  { required: true, message: t('passwordIsRequired') },
                  { min: 8, message: t('passwordLength') },
                ]}
              >
                <Input.Password type="password" />
              </Form.Item>
            </Space>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="tw-w-full tw-text-base tw-text-slate-900 tw-text-center tw-h-10 "
                loading={isLoading}
              >
                {t('signInBtn')}
              </Button>
            </Form.Item>
          </Space>
        </Form>

        <Link
          className="tw-text-base tw-h-10 tw-px-3.5 tw-py-1.5 tw-text-slate-800 "
          to="/password-recovery"
        >
          {t('forgotPassword')}
        </Link>
        {isError && (
          <div className="tw-text-center tw-text-s tw-text-red-600 my-4">
            {errorMessage}
          </div>
        )}

        <ContactUs
          classNameForParagraph="tw-text-sm tw-font-normal tw-text-slate-400 tw-fixed tw-bottom-0"
          classNameForText="tw-font-bold tw-text-slate-900"
        />
      </Flex>

      <BgImage
        bgImageClassName="tw-relative tw-hidden lg:tw-block lg:tw-w-2/5 tw-h-full lg:tw-bg-cover lg:tw-bg-no-repeat lg:tw-bg-right"
        loginPageBgImage={LoginImage}
      />
    </Flex>
  );
};

function mapStateToProps(state) {
  const { isLoading, error } = state.ui;

  return {
    isLoading,
    isError: error.isError,
    errorMessage: error.message,
  };
}
LoginForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  loginGoogleUser: PropTypes.func.isRequired,
  loginMicrosoftUser: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
};
LoginForm.defaultProps = {
  isError: false,
  errorMessage: '',
};
export default connect(mapStateToProps, {
  loginMicrosoftUser: loginMicrosoftUserAction,
  loginGoogleUser: loginGoogleUserAction,
  loginUser: loginUserAction,
})(LoginForm);
