import { combineReducers } from 'redux';

import RolesReducer from './RolesReducer';
import areaSelectionReducer from './areaSelectionReducer';
import authReducer from './authReducer';
import companyReducer from './companyReducer';
import dashboardLinkingReducer from './dashboardLinkingReducer';
import flashMessageReducer from './flashMessage';
import forecastStoreReducer from './forecastStoreReducer';
import forecastingDataReducer from './forecastingDataReducer';
import layerManagementReducer from './layerManagementReducer';
import locationOfInterestReducer from './locationOfInterestReducer';
import realEstateReducer from './realEstateReducer';
import reportReducer from './reportReducer';
import retailReducer from './retailReducer';
import timeSliderReducer from './timeSliderReducer';
import uiReducer from './uiReducer';
import userReducer from './userReducer';

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  roles: RolesReducer,
  company: companyReducer,
  ui: uiReducer,
  forecastingData: forecastingDataReducer,
  timeSlider: timeSliderReducer,
  forecastStore: forecastStoreReducer,
  flashMessages: flashMessageReducer,
  dashboardLinking: dashboardLinkingReducer,
  locationOfInterest: locationOfInterestReducer,
  report: reportReducer,
  areaSelection: areaSelectionReducer,
  layerManagement: layerManagementReducer,
  retail: retailReducer,
  realEstate: realEstateReducer,
});
