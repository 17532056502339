import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import { LoginPageCarousel } from '../login/loginPageComponents/LoginPageCarousel';
import { ResetPassword } from './ResetPassword';

const PasswordBackgroundWrapper = ({ children }) => (
  <div>
    <Helmet>
      <title>Password recovery - CHAOSarchitects</title>
    </Helmet>
    <div className="tw-w-full tw-h-screen lg:tw-relative">{children}</div>
  </div>
);

export const PasswordRecovery = () => (
  <PasswordBackgroundWrapper>
    <ResetPassword />
    <LoginPageCarousel />
  </PasswordBackgroundWrapper>
);

PasswordBackgroundWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
