import { List, Popover, Typography, theme } from 'antd';
import { keys } from 'ramda';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdExitToApp, MdSettings, MdTranslate } from 'react-icons/md';
import { RiFileChartLine } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';

import { AVAILABLE_LANGUAGES } from '../i18n';
import BrandIcon from '../images/chaos_icon.png';
import forecastIcon from '../images/icons/binocolo_white_bg.svg';
import realEstateIcon from '../images/icons/real-estate-analysis-sidebar.svg';
import retailIcon from '../images/icons/retail-analysis-sidebar.svg';
import brandImage from '../images/logo_white_new.svg';
import { features } from '../pages/forecast/helpers/features';

const { Text } = Typography;
const { Item } = List;
const { useToken } = theme;

const Sidebar = ({
  isSidebarOpen,
  user,
  logout,
  handleBackToForecast,
  availableGlobalFeatures,
  updateUserInfo,
}) => {
  const { t, i18n } = useTranslation('', { keyPrefix: 'dashboardView' });
  const { token } = useToken();

  const selectedLanguage = useMemo(
    () => user.settings?.language || 'en',
    [user.settings?.language],
  );

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const renderReportSideBarItem = () => (
    <li className="list-item">
      <NavLink
        to="reports"
        title={t('reports')}
        className={({ isActive }) =>
          isActive ? 'navlink--active' : 'navlink--default'
        }
        data-test-id="link-reports"
      >
        <span className="sidenav__icon">
          <RiFileChartLine className="brand-image fade" size={20} />
        </span>
        {isSidebarOpen && <span className="sidebar__text">{t('reports')}</span>}
      </NavLink>
    </li>
  );

  const languageListItems = keys(AVAILABLE_LANGUAGES).map((key) => {
    return { key, label: AVAILABLE_LANGUAGES[key] };
  });

  const renderRetailSideBarItem = () => (
    <li className="list-item">
      <NavLink
        to="retail"
        title={t('retail')}
        className={({ isActive }) =>
          isActive ? 'navlink--active' : 'navlink--default'
        }
      >
        <span className="sidenav__icon">
          <img src={retailIcon} alt="" />
        </span>
        {isSidebarOpen && <span className="sidebar__text">{t('retail')}</span>}
      </NavLink>
    </li>
  );

  const renderRealEstateSideBarItem = () => (
    <li className="list-item">
      <NavLink
        to="real-estate"
        title={t('realEstate')}
        className={({ isActive }) =>
          isActive ? 'navlink--active' : 'navlink--default'
        }
      >
        <span className="sidenav__icon">
          <img src={realEstateIcon} alt="" />
        </span>
        {isSidebarOpen && <span className="sidebar__text">{t('retail')}</span>}
      </NavLink>
    </li>
  );

  const onSelectLanguage = (key) => updateUserInfo({ language: key });

  const renderLanguagePopoverContent = () => (
    <List
      size="small"
      dataSource={languageListItems}
      renderItem={(item) => (
        <Item
          className="tw-cursor-pointer"
          onClick={() => onSelectLanguage(item.key)}
        >
          <Text
            style={{
              color: item.key === selectedLanguage ? token.colorPrimary : '',
            }}
          >
            {item.label}
          </Text>
        </Item>
      )}
    ></List>
  );

  const includesRetailAnalysis =
    availableGlobalFeatures?.includes(features.RETAIL_MARKET_ANALYSIS) ||
    availableGlobalFeatures?.includes(features.RETAIL_CATCHMENT_ANALYSIS);

  return (
    <div className={`sidebar ${isSidebarOpen ? 'expanded' : ''}`}>
      <header>
        <div className="brand text-center">
          {isSidebarOpen ? (
            <img
              className="brand-image fade"
              src={brandImage}
              alt="chaosarchitects-brand-logo"
            />
          ) : (
            <img
              className="brand-image fade"
              src={BrandIcon}
              alt="chaosarchitects-brand-icon"
            />
          )}
        </div>

        <ul className="list-group">
          <div className="w-full">
            <li className="list-item">
              <NavLink
                to="insights"
                title={t('insights')}
                className={({ isActive }) =>
                  isActive ? 'navlink--active' : 'navlink--default'
                }
                data-test-id="link-forecast"
                onClick={() => handleBackToForecast()}
              >
                <span className="sidenav__icon">
                  <img
                    style={{
                      height: 16,
                      marginLeft: 2,
                      marginRight: 3,
                    }}
                    className="brand-image fade"
                    src={forecastIcon}
                    alt={t('forecast')}
                  />
                </span>
                {isSidebarOpen && (
                  <span className="sidebar__text">{t('insights')}</span>
                )}
              </NavLink>
            </li>
            {includesRetailAnalysis && renderRetailSideBarItem()}
            {availableGlobalFeatures.includes(features.REAL_ESTATE_ANALYSIS) &&
              renderRealEstateSideBarItem()}
            {availableGlobalFeatures.includes(features.REPORTING) &&
              renderReportSideBarItem()}
          </div>
          <div
            className="w-full"
            style={{ backgroundColor: 'rgba(114,115,115,0.2)' }}
          >
            <li className="list-item">
              <Popover
                trigger="hover"
                placement="right"
                content={renderLanguagePopoverContent()}
              >
                <span className="sidenav__icon tw-justify-center tw-py-3 tw-cursor-pointer">
                  <MdTranslate />
                </span>
              </Popover>
            </li>

            <li className="list-item">
              <NavLink
                to="settings"
                title={t('settings')}
                className={({ isActive }) =>
                  isActive ? 'navlink--active' : 'navlink--default'
                }
                data-test-id="link-settings"
              >
                <span className="sidenav__icon">
                  <MdSettings />
                </span>
                {isSidebarOpen && (
                  <span className="sidebar__text">{t('settings')}</span>
                )}
              </NavLink>
            </li>

            <li className="list-item">
              <button
                title={t('logout')}
                className="navlink--default cursor"
                onClick={() => logout()}
                data-test-id="link-logout"
              >
                <span className="sidenav__icon">
                  <MdExitToApp />
                </span>
                {isSidebarOpen && (
                  <span className="sidebar__text">{t('logout')}</span>
                )}
              </button>
            </li>
          </div>
        </ul>
      </header>
    </div>
  );
};

export default Sidebar;
