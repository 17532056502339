import { TARGET_CUSTOMER_SEARCH_HOUSEHOLD } from '../../../actions';
import { indicators as INDICATORS } from '../maps/constants';
import { householdTypesLegendTabs } from './advancedHouseholdTypes';

export const features = {
  INSIGHTS: 'insights',
  DEMOGRAPHICS: 'demographics',
  DEMOGRAPHICS_250_GRID: 'demographics-250-grid',
  DEMOGRAPHIC_FORECAST: 'demographic-forecast',
  ADVANCED_DEMOGRAPHICS: 'advanced-demographics',
  FOOTFALL: 'footfall',
  SERVICES: 'services',
  AREA_DEVELOPMENT: 'area-development',
  ASSETS: 'assets',
  LIVEABILITY: 'living-convenience',
  RESIDENTIAL_DEMAND_SUPPLY: 'residential-demand-supply',
  PROPERTY_MARKET: 'property-market',
  TENANT_SEARCH: 'tenant-search',
  CSV_DATA_UPLOAD: 'csv-data-upload',
  REPORTING: 'reporting',
  RETAIL_MARKET_ANALYSIS: 'retail-market-analysis',
  RETAIL_CATCHMENT_ANALYSIS: 'retail-catchment-analysis',
  REAL_ESTATE_ANALYSIS: 'real-estate-analysis',
  RETAIL_TELIA_FOOTFALL: 'retail-telia-footfall',
  AREA_DEV_GENERAL_ZONING_PLAN: 'area-dev-general-zoning-plan',
  CHART_CSV_DATA_EXPORT: 'chart-csv-data-export',
  REAL_ESTATE_REPORT_PDFS: 'real-estate-report-pdfs',
};

const indicatorGroup = {
  demandInsights: [
    {
      label: 'populationByAgeAndGender',
      name: 'Population by age and gender',
      group: 'Demand insights',
      isAvailable: false,
      feature: features.DEMOGRAPHICS,
      isDeprecatedQ3: true,
    },
    {
      label: 'populationByIncome',
      name: 'Population by income',
      group: 'Demand insights',
      isAvailable: false,
      feature: features.DEMOGRAPHICS,
      isDeprecatedQ3: true,
    },
    {
      label: 'unemployment',
      name: 'Unemployment',
      group: 'Demand insights',
      isAvailable: false,
      feature: features.DEMOGRAPHICS,
      isDeprecatedQ3: true,
    },
    {
      label: 'populationByEducation',
      name: 'Population by education',
      group: 'Demand insights',
      isAvailable: false,
      feature: features.DEMOGRAPHICS,
      isDeprecatedQ3: true,
    },
    {
      label: 'populationStructure',
      name: 'Population structure',
      group: 'Demand insights',
      isAvailable: false,
      feature: features.ADVANCED_DEMOGRAPHICS,
    },
    {
      label: 'advancedHouseholdTypes',
      name: 'Advanced household types',
      group: 'Demand insights',
      isAvailable: false,
      feature: features.ADVANCED_DEMOGRAPHICS,
    },
  ],
  supplyInsights: [
    {
      label: 'areaDevelopment',
      name: 'Area development',
      group: 'Supply insights',
      isAvailable: false,
      feature: features.AREA_DEVELOPMENT,
    },
    {
      label: 'residentialDemandAndSupply',
      name: 'Residential demand-supply',
      group: 'Supply insights',
      isAvailable: false,
      feature: features.RESIDENTIAL_DEMAND_SUPPLY,
    },
    {
      label: 'tenantSearch',
      name: 'Tenant search',
      group: 'Supply insights',
      isAvailable: false,
      feature: features.TENANT_SEARCH,
    },
    {
      label: 'propertyMarket',
      name: 'Property market',
      group: 'Supply insights',
      isAvailable: false,
      feature: features.PROPERTY_MARKET,
    },
  ],
  locationBenefits: [
    {
      label: 'footfall',
      name: 'Footfall',
      group: 'Location benefits',
      isAvailable: false,
      feature: features.FOOTFALL,
    },
    {
      label: 'servicesMix',
      name: 'Services mix',
      group: 'Location benefits',
      isAvailable: false,
      feature: features.SERVICES,
    },
    {
      label: 'livingConvenience',
      name: 'Living convenience',
      group: 'Location benefits',
      isAvailable: false,
      feature: features.LIVEABILITY,
    },
  ],
};

export const displayName = {
  'Population by age and gender': 'Age/gender',
  'Population by income': 'Income',
  Unemployment: 'Main activity',
  'Population by education': 'Education',
  'Services mix': 'Service mix',
  'Residential demand-supply': 'Residential market',
};

export const groupIndicators = ({ indexes, removeUnavailableIndicators }) => {
  let indicatorList = [...indexes];
  const group = {};
  Object.keys(indicatorGroup).forEach((key) => {
    indicatorGroup[key].forEach((ind) => {
      if (indicatorList.includes(ind.name)) {
        group[key]
          ? group[key].push({ ...ind, isAvailable: true })
          : (group[key] = [{ ...ind, isAvailable: true }]);
        indicatorList = indicatorList.filter((x) => x !== ind.name);
      } else if (!removeUnavailableIndicators) {
        group[key] ? group[key].push(ind) : (group[key] = [ind]);
      }
    });
  });
  return {
    private: indicatorList.map((ind) => ({ name: ind, isAvailable: true })),
    ...group,
  };
};

export function hasForecastUpgrade(state, legendState) {
  let forecastedYears =
    state.forecastingData[state.forecastStore.activeIndicator]?.data
      ?.forecastedYears || [];

  if (legendState) {
    forecastedYears = getAdvancedForecastedYears(
      state,
      legendState,
      forecastedYears,
    );
  }

  return (
    !!forecastedYears.length &&
    !state.user.availableFeatures.includes(features.DEMOGRAPHIC_FORECAST) &&
    forecastedYears.includes(state.timeSlider.year)
  );
}

function getAdvancedForecastedYears(state, legendState, defaultYears) {
  let data;
  switch (state.forecastStore.activeIndicator) {
    case INDICATORS['Advanced household types']:
      if (
        legendState.householdTypesState.selectedTab ===
        householdTypesLegendTabs.targetCustomerSearch
      ) {
        data = state.forecastingData?.[TARGET_CUSTOMER_SEARCH_HOUSEHOLD];
      } else {
        data =
          state.forecastingData?.[INDICATORS['Advanced household types']]?.[
            legendState.householdTypesState.selectedTab
          ];
      }

      return data?.forecastedYears || [];

    default:
      return defaultYears;
  }
}
