import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const BlurryLoadingImage = ({
  preview,
  image,
  alt,
  imageStyleClass,
  bgColor,
}) => {
  const [currentImage, setCurrentImage] = useState(preview);
  const [loading, setLoading] = useState(true);

  const fetchImage = (src) => {
    const loadingImage = new Image();
    loadingImage.src = src;
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
      setLoading(false);
    };
  };

  useEffect(() => {
    fetchImage(image);
  }, []);

  return (
    <img
      style={{
        filter: `${loading ? 'blur(20px)' : ''}`,
        transition: '1s filter linear',
        background: bgColor,
      }}
      src={currentImage}
      alt={alt}
      className={imageStyleClass}
    />
  );
};

BlurryLoadingImage.defaultProsp = {
  imageStyleClass: '',
  bgColor: 'transparent',
};
BlurryLoadingImage.propTypes = {
  preview: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  imageStyleClass: PropTypes.string,
  bgColor: PropTypes.string,
};

export default BlurryLoadingImage;
