import { UPDATE_REAL_ESTATE } from '../actions/types';

const initialState = {
  realEstateMasterMap: undefined,
  isAddNewRealEstatePlaceActive: false,
  selectedMunicipality: undefined,
  selectedInsights: [],
  selectedLocationRows: [],
  isPowerPointDownloadClick: false,
};

const realEstateReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REAL_ESTATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default realEstateReducer;
