import PropTypes from 'prop-types';
import React from 'react';

import blurImage from '../../../images/loginFormBlur-image.png';
import BlurryLoadingImage from './BlurryLoadingImage';

export const BgImage = ({ bgImageClassName, loginPageBgImage }) => (
  <div className={bgImageClassName}>
    <BlurryLoadingImage
      preview={blurImage}
      image={loginPageBgImage}
      alt="login-image"
      imageStyleClass="tw-w-full tw-h-full"
    />
  </div>
);

BgImage.propTypes = {
  bgImageClassName: PropTypes.string.isRequired,
  loginPageBgImage: PropTypes.string.isRequired,
};
