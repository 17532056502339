import React from 'react';
import { Helmet } from 'react-helmet';

import LoginForm from './login/loginPageComponents/LoginForm';
import { LoginPageCarousel } from './login/loginPageComponents/LoginPageCarousel';

const BackgroundWrapper = ({ htmlTitle, children }) => (
  <div>
    <Helmet>
      <title>{htmlTitle}</title>
    </Helmet>
    <div className="tw-w-full tw-h-screen lg:tw-relative">{children}</div>
  </div>
);

function LogIn() {

  return (
    <BackgroundWrapper htmlTitle="CHAOS dashboard">
      <LoginForm />
      <LoginPageCarousel />
    </BackgroundWrapper>
  );
}

export default LogIn;
