import { useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

const gaEvents = {
  // Dashboards
  SELECT_DASHBOARD: 'select_dashboard',
  SELECT_LEGEND: 'select_legend',
  EXPAND_TEXT: 'expand_text',
  FILTER_PLOT: 'filter_plot',
  SHOW_LABEL_ON_MAP: 'show_label_on_map',
  HIDE_LABEL_ON_MAP: 'hide_label_on_map',
  SELECT_EXPANDABLE_TEXT_BUTTON: 'select_expandable_text_button',
  CHART_CSV_DOWNLOAD: 'chart_csv_download',
  // Report
  CREATE_NEW_REPORT: 'create_new_report',
  OPEN_REPORT: 'open_report',
  PUBLISH_REPORT: 'publish_report',
  UNPUBLISH_REPORT: 'unpublish_report',
  DUPLICATE_REPORT: 'duplicate_report',
  DELETE_REPORT: 'delete_report',
  DOWNLOAD_REPORT: 'download_report',
  DROP_INSIGHT: 'drop_insight',
  SEARCH_INSIGHT: 'search_insight',
  CREATE_NEW_PROJECT: 'create_new_project',
  SEARCH_AREA_SELECTION_REPORT: 'search_area_selection_report',
  CONFIRM_AREA_SELECTION_REPORT: 'confirm_area_selection_report',
  SELECT_AREA_SELECTION_REPORT: 'select_area_selection_report',
  SELECT_AREA_SELECTION_MODE_REPORT: 'select_area_selection_mode_report',
  // Area selection
  SEARCH_AREA_SELECTION: 'search_area_selection',
  CONFIRM_AREA_SELECTION: 'confirm_area_selection',
  SELECT_AREA_SELECTION: 'select_area_selection',
  OPEN_RELEASE_NOTE: 'open_release_note',
  SELECT_AREA_SELECTION_HISTORY_ITEM: 'select_area_selection_history_item',
  SELECT_AREA_SELECTION_MODE: 'select_area_selection_mode',
  ACCESS_UNAUTHORIZED_AREA: 'access_unauthorized_area',
  // Retail
  OPEN_RETAIL: 'open_retail',
  RETAIL_SELECT_FEATURE: 'retail_select_feature',
  RETAIL_ADD_A_NEW_PLACE: 'retail_add_a_new_place',
  RETAIL_CONFIGURE_INDIVIDUAL_PLACE: 'retail_configure_individual_place',
  RETAIL_CSV_UPLOAD_START: 'retail_csv_upload_start',
  RETAIL_CSV_UPLOAD_OWN_PLACES: 'retail_csv_upload_own_places',
  RETAIL_CSV_UPLOAD_COMPETITOR_PLACES: 'retail_csv_upload_competitor_places',
  RETAIL_CSV_UPLOAD_POTENTIAL_PLACES: 'retail_csv_upload_potential_places',
  RETAIL_CSV_UPLOAD_CATCHMENT: 'retail_csv_upload_catchment',
  RETAIL_CSV_UPLOAD_COMPLETED: 'retail_csv_upload_completed',
  RETAIL_CSV_UPLOAD: 'retail_csv_upload',
  RETAIL_UPDATE_TARGET_CUSTOMER_DEFINITION:
    'retail_update_target_customer_definition',
  RETAIL_INSIGHTS_TABLE_SELECTION: 'retail_insights_table_selection',
  RETAIL_RUN_SIMULATION: 'retail_run_simulation',
  // Real Estate
  OPEN_REAL_ESTATE: 'open_real_estate',
  REAL_ESTATE_ADD_A_NEW_PLACE: 'real_estate_add_a_new_place',
  REAL_ESTATE_CONFIGURE_INDIVIDUAL_PLACE:
    'real_estate_configure_individual_place',
  REAL_ESTATE_INSIGHTS_TABLE_SELECTION: 'real_estate_insights_table_selection',
  REAL_ESTATE_OPEN_RENT_COMPARISON_VIEW:
    'real_estate_open_rent_comparison_view',
  REAL_ESTATE_SELECT_MUNICIPALITY_RENT_COMPARISON:
    'real_estate_select_municipality_rent_comparison',
  REAL_ESTATE_OPEN_RENT_PREDICTION_VIEW:
    'real_estate_open_rent_prediction_view',
  REAL_ESTATE_RENT_DISTRIBUTION_DOWNLOAD:
    'real_estate_rent_distribution_download',
  REAL_ESTATE_OPEN_RENT_SUMMARY_VIEW: 'real_estate_open_rent_summary_view',
  REAL_ESTATE_RENT_SUMMARY_DOWNLOAD: 'real_estate_rent_summary_download',
  REAL_ESTATE_SELECT_MUNICIPALITY_RENT_SUMMARY:
    'real_estate_select_municipality_rent_summary',
  // Common Event
  START_TOUR: 'start_tour',
  FINISH_TOUR: 'finish_tour',
};

const feature = {
  DASHBOARD: 'dashboards',
  REPORTING: 'reporting',
  RETAIL: 'retail',
  REAL_ESTATE: 'real_estate',
};

// Generic function to send GA events
function sendGAEvents(eventName, events = {}) {
  return ReactGA.event(eventName, {
    ...events,
  });
}

const useGAEvents = () => {
  const { activeDashboard, selectedYear, country } = useSelector((state) => ({
    activeDashboard: state.forecastStore.activeIndicator,
    selectedYear: state.timeSlider.year,
    country: state.forecastStore.country,
  }));

  const sendSelectLegendEvent = useCallback(
    (legendName) =>
      sendGAEvents(gaEvents.SELECT_LEGEND, {
        legend_name: legendName,
        feature: feature.DASHBOARD,
        active_dashboard: activeDashboard,
        year: selectedYear,
        country,
      }),
    [activeDashboard, selectedYear, country],
  );

  const sendGenericDashboardEvent = useCallback(
    (eventName, eventParams = {}) =>
      sendGAEvents(eventName, {
        feature: feature.DASHBOARD,
        active_dashboard: activeDashboard,
        year: selectedYear,
        country,
        ...eventParams,
      }),
    [activeDashboard, selectedYear, country],
  );

  return { sendSelectLegendEvent, sendGenericDashboardEvent };
};

// Send event which is specified to Reporting feature
function sendGAReportingEvent(eventName, eventsParams = {}) {
  sendGAEvents(eventName, { ...eventsParams, feature: feature.REPORTING });
}

// Send retail dashboard specific events
function sendGARetailEvent(eventName, subFeature = '', eventParams = {}) {
  sendGAEvents(eventName, {
    feature: feature.RETAIL,
    subFeature,
    ...eventParams,
  });
}

// Send real estate dashboard specific events
function sendGARealEstateEvent(eventName, eventParams = {}) {
  sendGAEvents(eventName, {
    feature: feature.REAL_ESTATE,
    ...eventParams,
  });
}

// Send event which isn't specific to any particular feature
function sendGACommonEvent(eventName, eventsParams = {}) {
  sendGAEvents(eventName, { ...eventsParams });
}

export {
  feature,
  gaEvents,
  sendGACommonEvent,
  sendGAEvents,
  sendGAReportingEvent,
  sendGARetailEvent,
  sendGARealEstateEvent,
  useGAEvents,
};
