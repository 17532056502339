import { Button, Flex, Form, Image, Input, Space, Typography } from 'antd';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import emailSentIcon from '../../images/icons/email-sent.svg';
import loginImage from '../../images/loginForm-image.png';
import { BgImage } from '../login/loginPageComponents/BgImage';
import { BrandLogo } from '../login/loginPageComponents/BrandLogo';
import { ContactUs } from '../login/loginPageComponents/ContactUs';

const { Paragraph, Title, Text } = Typography;

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    setIsError(false);

    try {
      await firebase.auth().sendPasswordResetEmail(values.email);
      setSuccess(true);
    } catch (error) {
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccess = () => {
    setSuccess(false);
    navigate('/');
  };

  const { t } = useTranslation('', {
    keyPrefix: 'loginPage',
  });

  return (
    <Flex
      horizontal="true"
      className="tw-w-full tw-h-screen tw-p-0 tw-justify-center tw-items-center tw-relative"
    >
      <Flex
        vertical
        style={{ height: '334px' }}
        className="tw-justify-center tw-items-center lg:tw-w-3/5"
      >
        <BrandLogo className="tw-w-14 tw-h-14 tw-items-center tw-justify-center" />

        <Form
          requiredMark={false}
          layout="vertical"
          style={{ maxWidth: '480px' }}
          onFinish={handleSubmit}
        >
          {success ? (
            <Flex
              vertical
              style={{ height: '334px', maxWidth: '480px' }}
              className="tw-justify-center tw-items-center "
            >
              <Title
                level={2}
                className="tw-text-center tw-font-medium tw-text-4xl leading-tight"
              >
                {t('emailSent')}
              </Title>
              <Image
                src={emailSentIcon}
                alt="email sent image"
                style={{ height: '100px', width: '100px' }}
                preview={false}
              />
              <Text className="tw-text-center tw-my-4 tw-text-xs">
                {t('checkYourEmail')}
              </Text>

              <Button
                type="primary"
                size="small"
                className="tw-w-full tw-text-base tw-text-slate-900 tw-text-center tw-h-10"
                onClick={handleSuccess}
              >
                {t('gotIt')}
              </Button>
            </Flex>
          ) : (
            <>
              <Title
                level={1}
                className="tw-text-center tw-font-medium tw-text-4xl leading-tight"
              >
                {t('resetPassword')}
              </Title>
              <Paragraph className="tw-text-center tw-text-sm tw-font-normal tw-text-slate-400">
                {t('enterYourEmailAddress')}
              </Paragraph>
              <Space direction="vertical" size="large" className="tw-w-full">
                <Form.Item
                  label={t('email')}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('emailIsRequired'),
                    },
                    { type: 'email', message: t('emailIsNotValid') },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    className="tw-w-full tw-text-base tw-text-slate-900 tw-text-center tw-h-10"
                  >
                    {t('send')}
                  </Button>
                </Form.Item>
              </Space>
            </>
          )}
        </Form>
        <Space direction="vertical" size="small" className="tw-w-full">
          {isError && (
            <Text className="text-center text-error text-s mb-1">
              {t('somethingWentWrong')}
            </Text>
          )}
        </Space>
        <ContactUs
          classNameForParagraph="tw-text-sm tw-font-normal tw-text-slate-400 tw-fixed tw-bottom-0"
          classNameForText="tw-font-bold tw-text-slate-900"
        />
      </Flex>

      <BgImage
        bgImageClassName="tw-relative tw-hidden lg:tw-block lg:tw-w-2/5 tw-h-full lg:tw-bg-cover lg:tw-bg-no-repeat lg:tw-bg-right"
        loginPageBgImage={loginImage}
      />
    </Flex>
  );
};
