import React from 'react'
import { Image } from 'antd'
import PropTypes from 'prop-types'
import DesignLogo from '../../../images/icons/logo_icon_redesign.svg'

export const BrandLogo = ({className}) => (
    <Image className={className} alt="chaosarchitects_brand_logo_black" src={DesignLogo} preview={false} />
)
  
BrandLogo.propTypes = {
    className: PropTypes.string.isRequired
}
