import { produce } from 'immer';

import {
  GET_AVAILABLE_FEATURES,
  GET_GLOBAL_FEATURES,
  GET_UNAVAILABLE_FEATURES,
  GET_USER,
  GET_USER_INFO,
  SET_USER_GUIDE_COMPLETED,
  SET_USER_ONBOARDING_SEEN,
  SET_USER_REAL_ESTATE_SETTINGS,
  SET_USER_RETAIL_SETTINGS,
  SET_USER_SETTINGS,
} from '../actions/types';

export const UI_PROPERTIES = {
  hideUnavailableDashboards: 'hideUnavailableDashboards',
};

const INITIAL_STATE = {
  info: {
    uiFlags: [],
    settings: {},
    onboardingSeen: [],
    guideCompleted: [],
  },
  availableCountries: [],
  globalFeatures: [],
  availableFeatures: [],
  unavailableFeatures: [],
};

/* eslint default-case: "error" */
const userReducer = produce((draft, action) => {
  switch (action.type) {
    case GET_USER:
      draft.info = { ...draft.info, ...action.payload };
      break;

    case GET_GLOBAL_FEATURES:
      draft.globalFeatures = action.payload;
      break;

    case GET_AVAILABLE_FEATURES:
      draft.availableFeatures = action.payload;
      break;

    case GET_UNAVAILABLE_FEATURES:
      draft.unavailableFeatures = action.payload;
      break;

    case GET_USER_INFO:
      draft.info = { ...draft.info, ...action.payload };
      draft.availableCountries = action.payload.availableCountries;
      break;

    case SET_USER_SETTINGS:
      if (draft?.info) {
        draft.info.settings = action.payload;
      }
      break;

    case SET_USER_RETAIL_SETTINGS:
      if (draft?.info) {
        draft.info.retailSettings = action.payload;
      }
      break;

    case SET_USER_REAL_ESTATE_SETTINGS:
      if (draft?.info) {
        draft.info.realEstateSettings = action.payload;
      }
      break;

    case SET_USER_ONBOARDING_SEEN:
      if (draft?.info) {
        draft.info.onboardingSeen = action.payload;
      }
      break;

    case SET_USER_GUIDE_COMPLETED:
      if (draft?.info) {
        draft.info.guideCompleted = action.payload;
      }
      break;

    // No Default
  }
}, INITIAL_STATE);

export default userReducer;
