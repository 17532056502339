import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import Root from './Root';
import CreatePassword from './pages/CreatePassword';
import LogIn from './pages/LogIn';
import PasswordReset from './pages/PasswordReset';
import { features } from './pages/forecast/helpers/features';
import { PasswordRecovery } from './pages/resetPassword/PasswordRecovery';

const AppRoutes = ({ isAuthenticated }) => {
  const routes = useMemo(
    () => (
      <Routes>
        {/* Private routes */}
        <Route path="/*" element={isAuthenticated ? <Root /> : <LogIn />}>
          <Route path="insights/*" element={<Root />} />
          <Route path="reports/*" element={<Root />} />
          <Route path="retail/*" element={<Root />} />
          <Route path="real-estate/*" element={<Root />} />
          <Route path="settings" element={<Root />} />
        </Route>

        <Route path="password-reset" Component={PasswordReset} />
        <Route path="create-password" Component={CreatePassword} />
        <Route path="password-recovery" Component={PasswordRecovery} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    ),
    [isAuthenticated],
  );
  return routes;
};
AppRoutes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.authenticated,
  hasInsights: Boolean(
    state?.user?.globalFeatures?.includes(features.INSIGHTS),
  ),
});

export default connect(mapStateToProps, null)(AppRoutes);
