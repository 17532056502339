// authentication
export const LOGIN_USER = 'login_user';
export const LOGOUT_USER = 'logout_user';

// user
export const GET_USER = 'get_user';

// user roles
export const GET_USER_ROLES = 'get_user_roles';

// available features
export const GET_AVAILABLE_FEATURES = 'get_available_features';
export const GET_UNAVAILABLE_FEATURES = 'get_unavailable_features';
export const GET_GLOBAL_FEATURES = 'get_global_features';
export const GET_USER_INFO = 'get_user_info';
export const SET_USER_SETTINGS = 'set_user_settings';
export const SET_USER_ONBOARDING_SEEN = 'set_user_onboarding_seen';

export const SET_USER_GUIDE_COMPLETED = 'set_user_guide_completed';

export const SET_USER_RETAIL_SETTINGS = 'set_user_retail_settings';

export const SET_USER_REAL_ESTATE_SETTINGS = 'set_user_real_estate_settings';

// company
export const GET_COMPANY = 'get_company';

// ui ideas
export const UI_LOADING = 'ui_loading';
export const UI_ERROR = 'ui_error';
export const NO_DATA = 'no_data';
export const ON_SCROLL = 'on_scroll';
export const SET_IS_DISTANCE_MEASUREMENT_ENABLED =
  'set_is_distance_measurement_enabled';
export const SET_IS_KNOWLEDGE_BASE_SIDEBAR_OPEN =
  'set_is_knowledge_base_sidebar_open';

// forecasting
export const GET_FORECAST_DATA = 'get_forecast_data';
export const GET_FORECAST_DATA_ASYNC = 'get_forecast_data_async';
export const GET_FORECAST_DATA_ERROR = 'get_forecast_data_error';
export const CLEAR_FORECAST_DATA = 'clear_forecast_data';
export const SET_DASHBOARD_MAP_BOUND = 'set_dashboard_map_bound';
export const REMOVE_DASHBOARD_DATA = 'remove_dashboard_data';
export const SET_COMMON_SLIDER_YEAR = 'set_common_slider_year';
export const SET_COMMON_SLIDER_RANGE = 'set_common_slider_range';
export const SET_COMMON_SLIDER_DATA = 'set_common_slider_data';
export const SET_SLIDER_INDICATOR_DATA = 'set_slider_indicator_data';
export const UPDATE_SLIDER_INDICATOR_DATA = 'update_slider_indicator_data';

// forecast store
export const SELECT_AREA = 'select_area';
export const SET_DATA_GROUPS = 'set_data_groups';
export const SET_ACTIVE_INDICATOR = 'set_active_indicator';
export const SET_SHOW_SIDE_CONTENT = 'set_show_side_content';
export const FORECAST_SET_IS_LOADING = 'forecast_set_is_loading';
export const ENABLE_DASHBOARD_LINKING = 'enable_dashboard_linking';
export const DISABLE_DASHBOARD_LINKING = 'disable_dashboard_linking';
export const RESET_DASHBOARD_LINKING_POPUP = 'reset_dashboard_linking_popup';
export const SET_SEARCH_BY_AREA = 'set_search_by_area_polygon';
export const SET_IS_FORECAST_ACTIVE = 'set_is_forecast_active';

// footfall dashboard
export const UPDATE_FOOTFALL_DATA = 'update_footfall_data';
export const GET_FOOTFALL_GRID_SUPPORTING_INFO =
  'get_footfall_grid_supporting_info';

// target customer
export const GET_TARGET_CUSTOMER_SEARCH = 'get_target_customer_search';
export const GET_TARGET_CUSTOMER_SEARCH_HOUSEHOLD =
  'get_target_customer_search_household';

// population structure
export const UPDATE_POPULATION_STRUCTURE_SELECTED_POSTCODE =
  'update_population_structure_selected_postcode';
export const GET_POPULATION_STRUCTURE_DATA = 'get_population_structure_data';

// advanced household types
export const UPDATE_HOUSEHOLD_TYPES_SELECTED_POSTCODE =
  'update_household_types_selected_postcode';
export const GET_ADVANCED_HOUSEHOLD_TYPES_DATA =
  'get_advanced_household_types_data';

// service mix
export const UPDATE_REACHABILITY_SELECTION = 'update_reachability_selection';
export const UPDATE_SERVICE_MIX_DATA = 'update_service_mix_data';
export const GET_SERVICE_MIX_DATA = 'GET_SERVICE_MIX_DATA';

// residential supply demand
export const GET_RESIDENTIAL_DEMAND_SUPPLY = 'get_residential_supply_demand';
export const CLEAR_SELECTED_GRID_SUPPORTING_INFO =
  'clear_selected_grid_supporting_info';

// property market
export const GET_PROPERTY_MARKET = 'get_property_market';
export const CLEAR_PROPERTY_MARKET = 'clear_property_market';

// area development
export const GET_AREA_DEVELOPMENT = 'get_area_development';

// flash message
export const SHOW_FLASH_MESSAGE = 'show_flash_message';
export const HIDE_FLASH_MESSAGE = 'hide_flash_message';

// user point of interest
export const GET_POINT_OF_INTEREST = 'get_point_of_interest';
export const CLEAR_POINT_OF_INTEREST = 'clear_point_of_interest';
export const SET_POINT_OF_INTEREST_VISIBLE = 'update_point_of_interest_visible';

// location of interest
export const UPDATE_LOCATION_OF_INTEREST = 'update_location_of_interest';

// layer management
export const UPDATE_LAYER_MANAGEMENT = 'update_layer_management';

// Reports
export const UPDATE_REPORT = 'update_report';
export const UPDATE_REPORT_COMPONENT_PROPS = 'update_report_component_props';
export const ADD_TO_REPORT_RECENTLY_USED_INSIGHTS =
  'add_to_report_recently_used_insights';
export const REFRESH_REPORT_STATE = 'refresh_report_state';
export const SET_SELECTED_MENU_TAB = 'set_selected_menu_tab';
export const SET_IS_REPORT_AREA_SELECTION_ENABLED =
  'set_is_report_area_selection_enabled';
export const SET_SELECTED_EDITOR = 'report_set_selected_editor';

// Area Selection
export const SET_HEXAGON_IDS = 'set_hexagon_ids';
export const SET_AREA_SELECTION_MODE = 'set_area_selection_mode';
export const SET_SEARCH_AREA_OUTLINE_GEOMETRY =
  'set_search_area_outline_geometry';

// Retails
export const UPDATE_RETAIL = 'update_retail';

// Real Estate
export const UPDATE_REAL_ESTATE = 'update_real_estate';
