import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Carousel, ConfigProvider, Radio, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CarouselArrow } from './CarouselArrow';

const { Title, Paragraph } = Typography;
export const LoginPageCarousel = () => {
  const { t } = useTranslation('', {
    keyPrefix: 'loginPage.loginPageCarousel',
  });

  const carouselItems = [
    {
      title: t('areaDevelopment.title'),
      content: t('areaDevelopment.content'),
    },
    {
      title: t('propertyMarket.title'),
      content: t('propertyMarket.content'),
    },
    {
      title: t('populationStructure.title'),
      content: t('populationStructure.content'),
    },
    {
      title: t('residentialMarket.title'),
      content: t('residentialMarket.content'),
    },
    {
      title: t('marketAnalysis.title'),
      content: t('marketAnalysis.content'),
    },
  ];

  const carouselRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleRadioChange = (e) => {
    const slideIndex = e.target.value;
    setCurrentSlide(slideIndex);
    carouselRef.current.goTo(slideIndex);
  };

  return (
    <div
      style={{
        border: '2px solid white',
        backdropFilter: 'blur(6px)',
      }}
      className="tw-hidden lg:tw-block lg:tw-absolute lg:tw-bottom-10 lg:tw-right-12 lg:tw-rounded-lg lg:tw-w-1/3 lg:tw-h-1/4 "
    >
      <Carousel
        arrows={false}
        dots={false}
        infinite
        ref={carouselRef}
        beforeChange={(from, to) => setCurrentSlide(to)}
        autoplay
        autoplaySpeed={6000}
      >
        {carouselItems.map((item) => (
          <div key={item.title} className="tw-px-8 tw-pt-4">
            <Title
              style={{
                color: 'white',
                fontSize: '2vw',
                fontWeight: '500',
                lineHeight: '42px',
              }}
            >
              {item.title}
            </Title>
            <Paragraph
              style={{
                color: 'white',
                fontSize: '20px',
                fontWeight: '400',
                lineHeight: '22px',
              }}
            >
              {item.content}
            </Paragraph>
          </div>
        ))}
      </Carousel>

      <CarouselArrow
        shape="circle"
        icon={<ArrowLeftOutlined />}
        className="tw-absolute tw-right-20 tw-translate-y-1/2"
        style={{ bottom: '8%' }}
        onClick={() => carouselRef.current.prev()}
      />
      <CarouselArrow
        shape="circle"
        icon={<ArrowRightOutlined />}
        className="tw-absolute tw-right-8 tw-translate-y-1/2"
        style={{ bottom: '8%' }}
        onClick={() => carouselRef.current.next()}
      />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: 'white',
            colorBgContainer: 'transparent',
          },
          components: {
            Radio: {
              buttonCheckedBg: 'white',
              colorBorder: 'white',
            },
          },
        }}
      >
        <Radio.Group
          onChange={handleRadioChange}
          value={currentSlide}
          style={{
            position: 'absolute',
            bottom: '5%',
            left: '32px',
            transform: 'translateY(-50%)',
          }}
          gh
        >
          {carouselItems.map((item, index) => (
            <Radio key={item.content} value={index} type="primary" />
          ))}
        </Radio.Group>
      </ConfigProvider>
    </div>
  );
};
